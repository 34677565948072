<template>
    <div id="app">
        <el-row>
            <el-col :span="24">
                <el-card shadow="hover">
                    <div slot="header" class="header-content">
                        <span class="custom-font">资产工具</span>
                    </div>
                    <!-- 下拉框 -->
                    <el-select v-model="selectedName" placeholder="请选择机房" @change="onSelectChange">
                        <el-option v-for="item in datas" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                    <!-- <p>您选择了: {{ selectedName }}</p> -->
                    <p></p>
                    <template>
                        <el-table :data="selectedData ? selectedData.tableData : []" border style="width: 100%"
                            :span-method="objectSpanMethod">
                            <el-table-column prop="type" label="分类" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="name" label="名称" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="sum" label="数量" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column label="资产编码" :show-overflow-tooltip="true">
                                <template #default="{ row }">
                                    <div v-if="row.assetId !== null && typeof row.assetId === 'string'">
                                        <div v-html="row.assetId.replace(/\n/g, '<br>')"></div>
                                    </div>
                                    <div v-else>
                                        {{ row.assetId }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="messages" label="备注" :show-overflow-tooltip="true">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<style>
/* 自定义字体样式 */
.custom-font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    font-weight: bold;
    /* 加粗 */
    font-size: 24px;
    /* 加大字体 */
    text-align: center;
    /* 居中对齐 */
}

/* header 居中对齐 */
.header-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<script>
export default {
    data() {
        return {
            selectedName: null,
            selectedData: null,
            datas: [
                {
                    id: 1,
                    name: '广州移动华新园AC-G16',
                    tableData: [
                        {
                            "type": "办公与通讯设备",
                            "name": "办公电脑",
                            "sum": 10,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "值班手机",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "打印机",
                            "sum": 2,
                            "assetId": "CNB3N9SBPG，CNB3R1Y62M",
                            "messages": "CNB3N9SBPG坏件"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "标签打印机",
                            "sum": 0,
                            "assetId": null,
                            "messages": "打印机覆盖"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "碎纸机",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "显示器",
                            "sum": 0,
                            "assetId": null,
                            "messages": "运营商提供"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "键盘",
                            "sum": 0,
                            "assetId": null,
                            "messages": "运营商提供"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "扫码枪",
                            "sum": 5,
                            "assetId": "19205010552827\n\n21205010555024\n\n22015010557121\n\n21336010566551\n\n202009009326",
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "测试线",
                            "sum": 5,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤光功率计",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤识别仪",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤测试笔",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "网线测线仪",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤清洁器",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "Console线",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "维护与维修工具",
                            "name": "静电手环",
                            "sum": 4,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "维护与维修工具",
                            "name": "螺丝刀",
                            "sum": 3,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "维护与维修工具",
                            "name": "压线钳",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "维护与维修工具",
                            "name": "斜口钳",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "操作记录仪",
                            "sum": 9,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "红外测温仪",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "温湿度检测仪",
                            "sum": 0,
                            "assetId": null,
                            "messages": "运营商提供"
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "风速仪",
                            "sum": 0,
                            "assetId": null,
                            "messages": "不涉及"
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "医疗急救箱",
                            "sum": 1,
                            "assetId": null,
                            "messages": null
                        }
                    ]
                },
                {
                    id: 2,
                    name: '清远腾讯清新DC',
                    tableData: [
                        {
                            "type": "办公与通讯设备",
                            "name": "办公电脑",
                            "sum": 18,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "值班手机",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "打印机",
                            "sum": 5,
                            "assetId": "E74216D9N996708\nE74215A8N356292\nE74216F0N473272\nE74216G0N514430\nE74216G1N867334",
                            "messages": ""
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "标签打印机",
                            "sum": 0,
                            "assetId": "",
                            "messages": "打印机代替"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "碎纸机",
                            "sum": 4,
                            "assetId": "60484401461\n65949440050\n60510400802\n60510404547",
                            "messages": ""
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "显示器",
                            "sum": 0,
                            "assetId": "",
                            "messages": "客户提供"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "键盘",
                            "sum": 0,
                            "assetId": "",
                            "messages": "客户提供"
                        },
                        {
                            "type": "办公与通讯设备",
                            "name": "扫码枪",
                            "sum": 5,
                            "assetId": "21015010553150\n19205010552136\n21015010552913\n21015010553007\n21015055320005",
                            "messages": ""
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "测试线",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤光功率计",
                            "sum": 2,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤识别仪",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤测试笔",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "网线测线仪",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "光纤清洁器",
                            "sum": 0,
                            "assetId": "",
                            "messages": "代采购"
                        },
                        {
                            "type": "网络与测试工具",
                            "name": "Console线",
                            "sum": 3,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "维护与维修工具\n",
                            "name": "静电手环",
                            "sum": 8,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "维护与维修工具\n",
                            "name": "螺丝刀",
                            "sum": 4,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "维护与维修工具\n",
                            "name": "压线钳",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "维护与维修工具\n",
                            "name": "斜口钳",
                            "sum": 2,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "操作记录仪",
                            "sum": 8,
                            "assetId": "",
                            "messages": ""
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "红外测温仪",
                            "sum": 0,
                            "assetId": "",
                            "messages": "客户提供"
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "温湿度检测仪",
                            "sum": 0,
                            "assetId": "",
                            "messages": "客户提供"
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "风速仪",
                            "sum": 0,
                            "assetId": "",
                            "messages": "不涉及"
                        },
                        {
                            "type": "安全与环境监测工具",
                            "name": "医疗急救箱",
                            "sum": 1,
                            "assetId": "",
                            "messages": ""
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        sortedTableData() {
            return this.tableData.sort((a, b) => a.type.localeCompare(b.type));
        }
    },
    created() {
        // 页面加载时自动选择第一个机房
        if (this.datas.length > 0) {
            this.selectedName = this.datas[0].name;
            this.selectedData = this.datas[0];
        }
    },
    methods: {
        onSelectChange(value) {
            // 更新 selectedData
            const selectedItem = this.datas.find(item => item.name === value);
            this.selectedData = selectedItem;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: this.getRowspan(rowIndex),
                        colspan: 1
                    };
                } else if (this.selectedData.tableData[rowIndex].type === this.selectedData.tableData[rowIndex - 1].type) {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                } else {
                    return {
                        rowspan: this.getRowspan(rowIndex),
                        colspan: 1
                    };
                }
            }
        },
        getRowspan(rowIndex) {
            let count = 1;
            while (rowIndex + count < this.selectedData.tableData.length && this.selectedData.tableData[rowIndex].type === this.selectedData.tableData[rowIndex + count].type) {
                count++;
            }
            return count;
        }
    }
};
</script>